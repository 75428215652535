import React from 'react';
import * as R from 'ramda';
import { OuterClick } from 'react-outer-click';
import { compose, withState, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, AbsoluteBox, RelativeBox } from '../../../ui';
// forms
import { Info } from '../../ui';
import { Error, LabelBox } from './ui';
//////////////////////////////////////////////////

const getLabel = (label: any, props: Object, joiner: any = ' ') => {
  if (G.isFunction(label)) return label(props);

  if (G.isFirstItemArray(label)) {
    return R.compose(
      R.join(joiner),
      R.map((arr: Array) => R.ifElse(
        (item: Array) => R.or(
          R.isNil(G.getWindowLocaleWithoutDefault(R.head(item))),
          R.isEmpty(G.getWindowLocaleWithoutDefault(R.head(item))),
        ),
        (item: Array) => R.last(item),
        (item: Array) => G.getWindowLocale(R.head(item)),
        )(arr),
      ),
    )(label);
  }

  if (G.isArray(label)) {
    return R.ifElse(
      (item: Array) => R.or(
        R.isNil(G.getWindowLocaleWithoutDefault(R.head(item))),
        R.isEmpty(G.getWindowLocaleWithoutDefault(R.head(item))),
      ),
      (item: Array) => R.last(item),
      (item: Array) => G.getWindowLocale(R.head(item)),
    )(label);
  }

  if (G.isObject(label)) return getLabel(R.prop('label', label), props, R.prop('joiner', label));

  return label;
};

export const InputWrapper = (props: Object) => {
  const {
    width,
    error,
    label,
    infoText,
    hasError,
    children,
    infoStyles,
    isRequired,
    errorWidth,
    endIconName,
    labelStyles,
    withCloseIcon,
    onCloseCallback,
    inputWrapperStyles,
    additionalLabelStyles,
    additionalLabelComponent,
  } = props;

  const labelToUse = getLabel(label, props);

  const endIcon = R.path([endIconName], I);

  return (
    <RelativeBox {...G.spreadUiStyles(inputWrapperStyles)}>
      <LabelBox
        top={-13}
        left='5px'
        fontSize={11}
        color={G.getTheme('colors.darkGrey')}
        {...G.spreadUiStyles(labelStyles)}
      >
        <Flex>
          <Box className={G.ifElse(isRequired, 'required', 'not-required')}>
            {labelToUse}
          </Box>
          {
            G.isNotNilAndNotEmpty(infoText) &&
            <Info {...G.spreadUiStyles(infoStyles)} text={infoText} />
          }
        </Flex>
      </LabelBox>
      { withCloseIcon &&
        <AbsoluteBox
          top={12}
          zIndex={11}
          cursor='pointer'
          left={R.subtract(width, 14)}
          onClick={() => G.callFunctionWithArgs(onCloseCallback, null)}
        >
          {I.closeIcon(G.getTheme('colors.dark.blue'))}
        </AbsoluteBox>
      }
      { endIcon &&
        <AbsoluteBox
          top={12}
          zIndex={11}
          cursor='pointer'
          left={R.subtract(width, 18)}
          onClick={() => G.callFunctionWithArgs(onCloseCallback, null)}
        >
          {endIcon(G.getTheme('colors.dark.blue'))}
        </AbsoluteBox>
      }
      {children}
      {hasError && <Error title={error} width={R.or(errorWidth, width)}>{error}</Error>}
      {
        G.isNotNilAndNotEmpty(additionalLabelComponent) &&
        <AbsoluteBox {...G.spreadUiStyles(additionalLabelStyles)}>
          {additionalLabelComponent}
        </AbsoluteBox>
      }
    </RelativeBox>
  );
};

const enhance = compose(
  withState('zIndex', 'setZIndex', ({ inputWrapperStyles }: Object) => {
    const zIndex = R.path(['zIndex'], inputWrapperStyles);

    return zIndex;
  }),
  withHandlers({
    handleClick: (props: Object) => () => {
      const { setZIndex, inputWrapperStyles } = props;

      const zIndex = R.pathOr(1000, ['zIndexOnClick'], inputWrapperStyles);

      setZIndex(zIndex);
    },
    handleOuterClick: (props: Object) => (event: Object) => {
      const { setZIndex, inputWrapperStyles } = props;

      // NOTE: like not good solution. Check  better one or remove comment after testing
      if (G.isNotNilAndNotEmpty(event)) {
        const { type, srcElement } = event;

        if (G.isAllTrue(
          R.includes('react-datepicker', srcElement.className),
          R.equals(type, 'mousedown'),
        )) return;
      }

      const zIndex = R.path(['zIndex'], inputWrapperStyles);

      setZIndex(zIndex);
    },
  }),
);

export const InputWrapperWithClickZIndex = enhance((props: Object) => {
  const {
    width,
    label,
    error,
    zIndex,
    hasError,
    children,
    errorTop,
    isRequired,
    handleClick,
    endIconName,
    withCloseIcon,
    onCloseCallback,
    handleOuterClick,
    inputWrapperStyles,
  } = props;

  const labelToUse = getLabel(label, props);

  const endIcon = R.path([endIconName], I);

  return (
    <OuterClick
      {...G.spreadUiStyles(inputWrapperStyles)}
      zIndex={zIndex}
      as={RelativeBox}
      onClick={handleClick}
      onOuterClick={handleOuterClick}
    >
      <LabelBox
        top={-13}
        left='5px'
        fontSize={11}
        color={G.getTheme('colors.darkGrey')}
      >
        <Box className={G.ifElse(isRequired, 'required', 'not-required')}>
          {labelToUse}
        </Box>
      </LabelBox>
      { withCloseIcon &&
        <AbsoluteBox
          top={12}
          zIndex={11}
          cursor='pointer'
          left={R.subtract(width, 14)}
          onClick={() => G.callFunctionWithArgs(onCloseCallback, null)}
        >
          {I.closeIcon(G.getTheme('colors.dark.blue'))}
        </AbsoluteBox>
      }
      { endIcon &&
        <AbsoluteBox
          top={12}
          zIndex={11}
          cursor='pointer'
          left={R.subtract(width, 18)}
          onClick={() => G.callFunctionWithArgs(onCloseCallback, null)}
        >
          {endIcon(G.getTheme('colors.dark.blue'))}
        </AbsoluteBox>
      }
      {children}
      {hasError && <Error title={error} width={width} top={errorTop}>{error}</Error>}
    </OuterClick>
  );
});

export const CheckboxWrapper = (props: Object) => {
  const { label, children, labelStyles, inputWrapperStyles, checkboxLabelStyles = {} } = props;

  const labelToUse = getLabel(label, props);

  return (
    <Flex {...inputWrapperStyles}>
      <Box
        pl='5px'
        mr='8px'
        fontSize={11}
        color={G.getTheme('colors.greyMatterhorn')}
        {...G.spreadUiStyles(labelStyles)}
        {...checkboxLabelStyles}
      >
        {labelToUse}
      </Box>
      {children}
    </Flex>
  );
};

export const ToggleWrapper = (props: Object) => {
  const { label, children, infoText, infoStyles, labelStyles, inputWrapperStyles } = props;

  const labelToUse = getLabel(label, props);

  return (
    <Flex {...inputWrapperStyles}>
      <Box
        pl='5px'
        mr='8px'
        fontSize={11}
        mb={R.prop('mb', labelStyles)}
        color={G.getTheme('colors.darkGrey')}
      >
        {labelToUse}
        {
          G.isNotNilAndNotEmpty(infoText) &&
          <Info {...G.spreadUiStyles(infoStyles)} text={infoText} />
        }
      </Box>
      {children}
    </Flex>
  );
};
